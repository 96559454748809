



























import { Component, Vue, Watch } from 'vue-property-decorator'
import Loading from '@/app/Loading.vue'
import { HOME_ROUTE_NAME } from '@/navigation/router'
import { ProfileStore } from '@/profile/ProfileStore'

@Component({
  components: { Loading }
})
export default class Pointing extends Vue {
  handle: string = this.remoteHandle

  get isReady(): boolean {
    return this.$store.state.profile.isReady && this.$store.state.profile.remoteProfile
  }

  get email(): string {
    return this.$store.state.profile.remoteProfile.email
  }

  get name(): string {
    return this.$store.state.profile.remoteProfile.name
  }

  get changed(): boolean {
    return this.handle !== this.remoteHandle
  }

  get remoteHandle(): string {
    return this.$store.state.profile.remoteProfile && this.$store.state.profile.remoteProfile.handle ? this.$store.state.profile.remoteProfile.handle : ''
  }

  saveProfile() {
    this.$store.dispatch(ProfileStore.ACTION_UPDATE_PROFILE, {
      handle: this.handle
    })
  }

  @Watch('$store.state.profile.remoteProfile')
  setupProfileCopy() {
    this.handle = this.remoteHandle
  }

  @Watch('$store.state.profile.signedIn')
  watchForLogout() {
    if (!this.$store.state.profile.signedIn) {
      this.$router.push({ name: HOME_ROUTE_NAME })
    }
  }
}
