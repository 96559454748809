



























import { Component, Vue } from 'vue-property-decorator'
import { PointingSession } from './PointingSessionStore'
import UserDisplayName from '../user/UserDisplayName.vue'
import { User } from '@/user/user'

@Component({
  components: { UserDisplayName },
  props: {
    session: Object
  }
})
export default class PointingResults extends Vue {
  session?: PointingSession

  get users():Array<User> {
    if (!this.session) {
      return []
    }
    const ret = this.session.participants
    if (this.session.facilitatorPoints) {
      ret.push(this.session.facilitator)
    }
    return ret
  }
}
