





















import { Vue, Component } from 'vue-property-decorator'
import MainNav from './navigation/MainNav.vue'
import { AppStore } from '@/app/AppStore'
import { PointingSessionStore } from '@/pointing/PointingSessionStore'
import { ProfileStore } from '@/profile/ProfileStore'

@Component({
  components: {
    MainNav
  }
})
export default class App extends Vue {
  created() {
    this.$store.dispatch('initialize')
  }

  get currentError(): null | string {
    return this.$store.state.app.errorToAck
  }

  get hasRemoteError(): boolean {
    return this.$store.state.app.errorToAck != null
  }

  set hasRemoteError(error) {
    if (!error) {
      this.$store.dispatch(AppStore.ACTION_ACK_REMOTE_ERROR)
    }
  }
}
