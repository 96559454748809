



















import SignIn from '@/profile/SignIn.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    SignIn
  }
})
export default class MainNav extends Vue {
  get isSignedIn(): boolean {
    return this.$store.state.profile.signedIn
  }
}
