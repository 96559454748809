




































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { PointingSession, PointingSessionStore } from '@/pointing/PointingSessionStore'
import Loading from '@/app/Loading.vue'
import UserDisplayName from '@/user/UserDisplayName.vue'
import { v4 as uuidv4 } from 'uuid'
import Pointing from '@/pointing/Pointing.vue'
import PointingResults from '@/pointing/PointingResults.vue'
import { joinSession, watchSession } from '@/pointing/pointing'
import { AppStore } from '@/app/AppStore'

@Component({
  components: { PointingResults, Pointing, UserDisplayName, Loading }
})
export default class Session extends Vue {
  userId: string = uuidv4()
  name: string = ''
  handle: string = ''
  detailsSet: boolean = false

  get isSignedIn(): boolean {
    return this.$store.state.profile.signedIn
  }

  get needDetails(): boolean {
    return !this.detailsSet
  }

  get hasConnectionId(): boolean {
    return !!this.$store.state.pointingSession.connectionId
  }

  get detailsIncomplete(): boolean {
    return this.name === ''
  }

  get sessionLoaded(): boolean {
    return !!this.$store.state.pointingSession.currentSession
  }

  get isParticipating(): boolean {
    return this.sessionLoaded && this.currentSession.participants.find((u) => {
      return u.userId === this.userId
    }) !== undefined
  }

  get currentSession(): PointingSession {
    return this.$store.state.pointingSession.currentSession
  }

  async joinSession() {
    this.detailsSet = true
    try {
      await joinSession(this.$store.state.profile.authToken, this.$route.params.sessionId, this.userId, {
        connectionId: this.$store.state.pointingSession.connectionId as string,
        name: this.isSignedIn ? this.$store.state.profile.remoteProfile.name : this.name,
        handle: this.isSignedIn ? this.$store.state.profile.remoteProfile.handle : this.handle
      })
    } catch (e) {
      await this.$store.dispatch(AppStore.ACTION_REGISTER_REMOTE_ERROR, e)
      this.detailsSet = false
    }
  }

  mounted() {
    this.routeParamsChanged()
  }

  @Watch('$store.state.profile.remoteProfile')
  profileLoaded() {
    this.routeParamsChanged()
  }

  @Watch('$route')
  async routeParamsChanged() {
    if (!this.hasConnectionId) {
      // we need our connection id
      return
    }
    const sessionId = this.$route.params.sessionId
    await this.$store.commit(PointingSessionStore.MUTATION_SET_SESSION_ID, sessionId)
    if (!this.isSignedIn) {
      try {
        await watchSession(this.$store.state.profile.authToken, sessionId, this.$store.state.pointingSession.connectionId as string)
      } catch (e) {
        await this.$store.dispatch(AppStore.ACTION_REGISTER_REMOTE_ERROR, e)
      }
    } else if (this.$store.state.profile.remoteProfile) {
      await this.joinSession()
    }
  }

  @Watch('hasConnectionId')
  watchConnectionId() {
    this.routeParamsChanged()
  }
}
