

























import { Component, Vue } from 'vue-property-decorator'
import { PointingSession } from './PointingSessionStore'
import { AppStore } from '@/app/AppStore'
import { User } from '@/user/user'
import Loading from '@/app/Loading.vue'
import { vote } from '@/pointing/pointing'

@Component({
  components: { Loading },
  props: {
    session: Object,
    userId: String
  }
})
export default class Pointing extends Vue {
  session?: PointingSession
  userId?: string
  loading: boolean = false

  get user(): User | undefined {
    if (!this.session) {
      return undefined
    }
    if (this.session.facilitator.userId === this.userId) {
      return this.session.facilitator
    }
    return this.session.participants.find((u) => {
      return u.userId === this.userId
    })
  }

  get currentVote(): string {
    if (!this.user || !this.user.currentVote) {
      return ''
    }
    return this.user.currentVote
  }

  async vote(value: string) {
    if (!this.session) {
      throw new Error('attempt to vote without a session')
    }
    if (!this.userId || !this.user) {
      throw new Error('attempt to vote without a user id')
    }
    this.loading = true
    try {
      await vote(this.$store.state.profile.authToken, this.session.sessionId, this.userId, value)
      this.user.currentVote = value
    } catch (e) {
      await this.$store.dispatch(AppStore.ACTION_REGISTER_REMOTE_ERROR, e)
    }
    this.loading = false
  }
}
