





























































import { Vue, Component, Watch } from 'vue-property-decorator'
import { PointingSession, PointingSessionStore } from '@/pointing/PointingSessionStore'
import Loading from '@/app/Loading.vue'
import { User } from '@/user/user'
import Pointing from '@/pointing/Pointing.vue'
import { updateSession, clearVotes as makeClearVotesAPICall, facilitateSession } from '@/pointing/pointing'
import { AppStore } from '@/app/AppStore'

@Component({
  components: { Pointing, Loading }
})
export default class Session extends Vue {
  votesShownClicked = false
  clearVotesClicked = false

  get hasConnectionId(): boolean {
    return !!this.$store.state.pointingSession.connectionId
  }

  get userId(): string {
    return this.currentSession ? this.currentSession.facilitator.userId as string : ''
  }

  get currentSession(): PointingSession | undefined {
    return this.$store.state.pointingSession.currentSession
  }

  get isVoting(): boolean {
    return !!this.currentSession && this.currentSession.facilitatorPoints
  }

  get waitingVotesShown(): boolean {
    return this.votesShownClicked && !this.votesShown
  }

  get waitingClearVotes(): boolean {
    return this.clearVotesClicked && this.votesShown
  }

  get teamEmpty(): boolean {
    return !this.currentSession || this.currentSession.participants.length === 0
  }

  get currentUsers(): Array<User> {
    return this.currentSession ? this.currentSession.participants : []
  }

  get votedCount(): number {
    return this.currentUsers.filter((u) => {
      return u.currentVote && u.currentVote !== ''
    }).length
  }

  get isSessionReady(): boolean {
    return !!this.$store.state.pointingSession.currentSession
  }

  get votesShown(): boolean {
    return this.$store.state.pointingSession.currentSession.votesShown
  }

  get userURL(): string {
    let port = ''
    if (window.location.protocol === 'http:' && window.location.port !== '80') {
      port = `:${window.location.port}`
    }
    return `${window.location.protocol}//${window.location.hostname}${port}/session/${this.$store.state.pointingSession.currentSession.sessionId}`
  }

  mounted() {
    this.routeParamsChanged()
    this.$store.commit(PointingSessionStore.MUTATION_SET_FACILITATING, true)
  }

  copyUserURLToClipboard() {
    navigator.clipboard.writeText(this.userURL)
  }

  async showVotes() {
    if (!this.currentSession) {
      throw Error('attempt to show votes without session')
    }
    this.clearVotesClicked = false
    this.votesShownClicked = true
    const sessionId = this.$route.params.sessionId
    const facilitatorSessionKey = this.$route.params.facilitatorSessionKey
    try {
      await updateSession(this.$store.state.profile.authToken, sessionId, facilitatorSessionKey, true, this.currentSession.facilitatorPoints)
    } catch (e) {
      await this.$store.dispatch(AppStore.ACTION_REGISTER_REMOTE_ERROR, e)
      this.votesShownClicked = false
    }
  }

  async clearVotes() {
    this.clearVotesClicked = true
    this.votesShownClicked = false
    const sessionId = this.$route.params.sessionId
    const facilitatorSessionKey = this.$route.params.facilitatorSessionKey
    try {
      await makeClearVotesAPICall(this.$store.state.profile.authToken, sessionId, facilitatorSessionKey)
    } catch (e) {
      await this.$store.dispatch(AppStore.ACTION_REGISTER_REMOTE_ERROR, e)
      this.votesShownClicked = false
    }
  }

  @Watch('$route')
  async routeParamsChanged() {
    if (!this.hasConnectionId) {
      // we need our connection id
      return
    }
    const sessionId = this.$route.params.sessionId
    await this.$store.commit(PointingSessionStore.MUTATION_SET_SESSION_ID, sessionId)
    const facilitatorSessionKey = this.$route.params.facilitatorSessionKey
    try {
      await facilitateSession(this.$store.state.profile.authToken, sessionId, this.$store.state.pointingSession.connectionId as string, facilitatorSessionKey)
    } catch (e) {
      await this.$store.dispatch(AppStore.ACTION_REGISTER_REMOTE_ERROR, e)
    }
  }

  @Watch('hasConnectionId')
  watchConnectionId() {
    this.routeParamsChanged()
  }
}
